import React from "react"
import { Link } from "gatsby"
import { FaLinkedin, FaTwitter, FaYoutube } from "react-icons/fa"
import SubButton from "./ButtonSub"
import "../styles/footerNew.scss"
import "../styles/SubscribeButton.scss"
import Accordion from "./MenuAccordion"
import addToMailchimp from "gatsby-plugin-mailchimp"
import LogoImg from "../images/logo.png"

class FooterNew extends React.Component {
  render() {
    return (
      <div className="footernew-container-wrap">
        <div className="footernew-row">
          <div className="footernew-links">
            <div
              className="fn-contacts"
              style={{ display: "flex", flexDirection: "row", width: "100%" }}
            >
              {/* Contact Info */}
              <div className="footernew-half-container">
                <div
                  style={{
                    fontSize: "14px",
                    lineHeight: "1",
                    color: "#fff",
                  }}
                >
                  <p
                    style={{
                      color: "#1ab4e9",
                      lineHeight: "1",
                      paddingTop: "40px",
                    }}
                  >
                    US
                  </p>
                  <p style={{ lineHeight: "150%" }}>
                    320 Seven Springs Way <br />
                    Ste 250 <br />
                    Brentwood, TN 37027
                    <br />
                    +1 347 674 4927
                  </p>
                </div>
              </div>

              <div className="footernew-half-container">
                <div
                  style={{
                    fontSize: "14px",
                    lineHeight: "1",
                    color: "#fff",
                  }}
                >
                  <p
                    style={{
                      color: "#1ab4e9",
                      lineHeight: "1",
                      paddingTop: "40px",
                    }}
                  >
                    UK
                  </p>
                  <p style={{ lineHeight: "150%" }}>
                    184 Shepherds Bush Road
                    <br />
                    London W6 7NL <br />
                    United Kingdom
                  </p>
                </div>
              </div>

              <div className="footernew-half-container">
                <div
                  style={{
                    fontSize: "14px",
                    lineHeight: "1",
                    color: "#fff",
                    marginLeft: "0",
                  }}
                >
                  <p
                    style={{
                      color: "#1ab4e9",
                      lineHeight: "1",
                      paddingTop: "40px",
                    }}
                  >
                    Germany
                  </p>
                  <p style={{ lineHeight: "150%" }}>
                    Hasselberg 5 <br />
                    D-50181 Bedburg <br />
                    Germany <br />
                    +49 (0) 170 116 1959
                  </p>
                </div>
              </div>

              <div className="footernew-half-container">
                <div
                  style={{
                    fontSize: "14px",
                    lineHeight: "1",
                    color: "#fff",
                    marginLeft: "0",
                  }}
                >
                  <p
                    style={{
                      color: "#1ab4e9",
                      lineHeight: "1",
                      paddingTop: "40px",
                    }}
                  >
                    Netherlands
                  </p>
                  <p style={{ lineHeight: "150%" }}>
                    Dorresteinweg <br />
                    31 3762 KG Soest <br />
                    The Netherlands <br />
                    +31 61 170 9321
                  </p>
                </div>
              </div>
              <div className="footernew-half-container">
                <div
                  style={{
                    fontSize: "14px",
                    lineHeight: "1",
                    color: "#fff",
                    marginLeft: "0",
                  }}
                >
                  <p
                    style={{
                      color: "#1ab4e9",
                      lineHeight: "1",
                      paddingTop: "40px",
                    }}
                  >
                    Nottingham
                  </p>
                  <p style={{ lineHeight: "150%" }}>
                    Sir Colin Campbell Bldg <br />
                    UNIP <br />
                    Triumph Rd <br />
                    Nottingham <br />
                    NG7 2TU
                    <br />
                    {/* +44 (0) 203 002 3750 */}
                  </p>
                </div>
              </div>
            </div>
            {/* <div className="footernew-half-container"></div> */}
            <div className="footernew-half-container4">
              <div
                style={{
                  fontSize: "14px",
                  lineHeight: "1",
                  color: "#fff",
                  marginLeft: "0",
                  verticalAlign: "middle",
                }}
              >
                {/* <p style={{color:"#1ab4e9", lineHeight:"0"}}></p> */}

                <div
                  className="valfoot"
                  style={{
                    textAlign: "center",
                    paddingTop: "24px",
                    width: "60px",
                    height: "60px",
                    backgroundColor: "#1888ff",
                    borderRadius: "50%",
                  }}
                >
                  Adapt
                </div>
                <div
                  className="valfoot"
                  style={{
                    marginTop: "-0.5rem",
                    textAlign: "center",
                    paddingTop: "24px",
                    width: "60px",
                    height: "60px",
                    backgroundColor: "#6a925d",
                    borderRadius: "50%",
                  }}
                >
                  Grow
                </div>
                <div
                  className="valfoot"
                  style={{
                    marginTop: "-0.5rem",
                    textAlign: "center",
                    paddingTop: "24px",
                    width: "60px",
                    height: "60px",
                    backgroundColor: "#246209",
                    borderRadius: "50%",
                    borderBottomRightRadius: "0",
                  }}
                >
                  Benefit
                </div>
              </div>
            </div>

            {/* /////////////////////////////////////////////////////////////////////////// */}

            {/* Social Media Links */}

            <div className="footernew-half-container3">
              <div className="social-icons">
                <Link
                  className="social-icon-link"
                  to="https://www.linkedin.com/company/metsi-technologies/"
                  target="_blank"
                  aria-label="Linkedin"
                >
                  <FaLinkedin size="24px" />
                </Link>

                <Link
                  className="social-icon-link"
                  to={
                    "https://www.youtube.com/channel/UC0VPmemAMa8voU6e2RDeGYQ/videos"
                  }
                  target="_blank"
                  aria-label="Youtube"
                >
                  <FaYoutube size="24px" />
                </Link>

                <Link
                  className="social-icon-link"
                  to="https://twitter.com/metsitech?lang=en"
                  target="_blank"
                  aria-label="Twitter"
                >
                  <FaTwitter size="24px" />
                </Link>
              </div>
              <br />
              <div
                className="footer-logo-wrap"
                style={{
                  backgroundColor: "#21b7e0",
                  // border: "2px solid #fff",
                  // padding: "5px",
                  borderRadius: "0 50% 50% 50%",
                  height: "120px",
                  width: "120px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  // marginLeft: "45px",
                }}
              >
                <img
                  src={LogoImg}
                  alt=""
                  style={{
                    height: "80px",
                    width: "80px",
                    objectFit: "contain",
                  }}
                />
              </div>
              {/* <div className="metsi-bkglogo"></div> */}
              <div className="social-icons">
                <p
                  style={{ fontSize: "12px", lineHeight: "1.5", color: "#fff" }}
                >
                  <br />
                  {/* © Metsi Technologies */}
                  <br />
                  Built by{" "}
                  <strong>
                    <a href="https://auxo.digital" style={{ color: "#ffffff" }}>
                      Auxo Digital
                    </a>
                  </strong>
                  <br />
                  <Link to="/privacy" style={{ color: "#fff" }}>
                    Privacy Policy
                  </Link>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default FooterNew
